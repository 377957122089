import React, { useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { gsap } from 'gsap';

//Components
import useObserver from './useObserver';

const Stat = ({ data, animate }) => {
  const numberRef = useRef(null);
  const entryRef = useRef(null);

  let number = { value: 0 };

  useEffect(() => {
    if (entryRef && numberRef) {
      gsap.set(entryRef.current, {
        opacity: 0,
      });

      gsap.set(numberRef.current, {
        yPercent: 50,
        force3D: true,
      });
    }
  }, [entryRef, numberRef]);

  useEffect(() => {
    if (animate) {
      gsap.to(number, {
        value: parseInt(data.title, 10),
        duration: 2,
        delay: 0.5,
        onUpdate() {
          numberRef.current.querySelector(
            '.stat__title-number'
          ).innerHTML = number.value.toFixed(0);
        },
      });

      gsap.to(numberRef.current, {
        yPercent: 0,
        force3D: true,
        ease: 'power4.out',
        delay: 2.3,
        duration: 0.5,
      });

      gsap.to(entryRef.current, {
        opacity: 1,
        ease: 'power4.out',
        delay: 2.3,
        duration: 1,
      });
    }
  }, [animate]);

  return (
    <div className="col-lg-4 mb-6 mb-lg-0">
      <p ref={numberRef} className="stat__title text-white mb-4">
        <span className="stat__title-number">0</span>
        {data.title.match(/\D/)}
      </p>

      <p ref={entryRef} className="text-white text-title">
        {data.entry}
      </p>
    </div>
  );
};

const SectionStats = ({ data }) => {
  const [ref, setRef, animate] = useObserver({
    init: true,
    root: null,
    rootMargin: `0% 0% 0% 0%`,
    threshold: 0.7,
  });

  if (!data || !data.stats.length) {
    return null;
  }

  return (
    <section className="section-stats pt-7 pt-xl-12 pt-mg-17 pb-12 pb-xl-17 pb-mg-22">
      <div className="container">
        <div className="row">
          <div className="col-lg-11 col-xl-10">
            <div className="pl-mg-17 mb-7 mb-lg-9 mb-xl-12">
              <h2 className="text-hero mb-3">{data.title}</h2>

              <div className="markdown-preview pr-lg-30">
                <ReactMarkdown source={data.text} />
              </div>
            </div>
          </div>

          <div className="col-mg-11">
            <div className="bg-primary p-5 p-lg-8 py-xl-12 px-xl-17">
              <div ref={setRef} className="row">
                {data.stats.map(stat => (
                  <Stat data={stat} key={stat.title} animate={animate} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionStats;
