import React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../components/layout';
import SEO from '../components/seo';

//Template
import AboutTemplate from '../templates/pages/about';

const AboutPage = ({ data, pageContext }) => {
  return (
    <Layout lang={pageContext.langKey}>
      <SEO
        title={data.pagesJson.languages.bg.metaData.title}
        description={data.pagesJson.languages.bg.metaData.description}
      />

      <AboutTemplate
        data={data.pagesJson.languages.bg}
        lang="bg"
        contactsTitle="Свържете се с нас"
      />
    </Layout>
  );
};

export const ABOUT_PAGE_BG_QUERY = graphql`
  query ABOUT_PAGE_BG_QUERY {
    pagesJson(pageKey: { eq: "about" }) {
      languages {
        bg {
          metaData {
            title
            description
          }
          intro {
            title
            text
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
          }
          information {
            title
            text
            stats {
              title
              entry
            }
          }
          introduction {
            title
            entry
            image {
              childImageSharp {
                fluid(maxWidth: 696, maxHeight: 464) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
