import React, { useEffect } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ReactMarkdown from 'react-markdown';
import { gsap } from 'gsap';

//Components
import Image from './image';
import useObserver from './useObserver';

const translations = {
  en: {
    find: 'Find out about',
  },
  de: {
    find: 'Erfahren Sie mehr über',
  },
  bg: {
    find: 'Разберете повече за',
  },
};

const Block = ({ lang, block, i, dataLength }) => {
  const [ref, setRef, animate] = useObserver({
    init: true,
    root: null,
    rootMargin: `-20% 0% -20% 0%`,
  });

  const scrollToNext = e => {
    const section = e.target.closest('.section-blocks');
    const block = e.target.closest('.section__block');

    if (block.nextSibling) {
      window.scrollTo({
        top: block.nextSibling.offsetTop - 200,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: section.nextSibling.offsetTop - 100,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (ref && window.innerWidth > 767) {
      const title = ref.querySelector('.section__title');
      const entry = ref.querySelector('.markdown-preview');
      const btn = ref.querySelector('.btn');
      const section = ref.closest('.section-blocks');
      const imageWrapper = ref.querySelector('.section__block-image');
      const image = ref.querySelector('.gatsby-image-wrapper');

      gsap.set(image, {
        opacity: 0,
      });

      gsap.set(title, {
        opacity: 0,
        yPercent: 10,
        force3D: true,
      });

      gsap.set(entry, {
        opacity: 0,
        yPercent: 10,
        force3D: true,
      });

      gsap.set(btn, {
        opacity: 0,
      });

      if (section.classList.contains('section-blocks--alt')) {
        if (i % 2 === 0) {
          gsap.set(imageWrapper, {
            opacity: 0,
            xPercent: 100,
            force3D: true,
          });
        } else {
          gsap.set(imageWrapper, {
            opacity: 0,
            xPercent: -100,
            force3D: true,
          });
        }
      } else {
        if (i % 2 === 0) {
          gsap.set(imageWrapper, {
            opacity: 0,
            xPercent: -100,
            force3D: true,
          });
        } else {
          gsap.set(imageWrapper, {
            opacity: 0,
            xPercent: 100,
            force3D: true,
          });
        }
      }
    }
  }, [ref]);

  useEffect(() => {
    if (animate && window.innerWidth > 767) {
      const title = ref.querySelector('.section__title');
      const entry = ref.querySelector('.markdown-preview');
      const btn = ref.querySelector('.btn');
      const imageWrapper = ref.querySelector('.section__block-image');
      const image = ref.querySelector('.gatsby-image-wrapper');

      gsap.to(imageWrapper, {
        duration: 0.7,
        opacity: 1,
        xPercent: 0,
        force3D: true,
        ease: 'expo.out',
      });

      gsap.to(image, {
        duration: 0.4,
        opacity: 1,
        delay: 0.4,
        ease: 'power1.out',
      });

      gsap.to(title, {
        duration: 0.5,
        yPercent: 0,
        opacity: 1,
        delay: 0.7,
        ease: 'power0.easeNone',
      });

      gsap.to(entry, {
        duration: 0.5,
        yPercent: 0,
        opacity: 1,
        delay: 1,
        ease: 'power0.easeNone',
      });

      gsap.to(btn, {
        duration: 0.5,
        opacity: 1,
        delay: 1,
        ease: 'power0.easeNone',
      });
    }
  }, [animate]);

  return (
    <div
      ref={setRef}
      className={`section__block row align-items-start ${
        i < dataLength - 1 ? 'mb-12 mb-lg-18 mb-xl-25' : ''
      }`}
      key={block.slug}
    >
      <div className="section__block-content col-lg-6 pt-md-7">
        <h3 className="section__title text-mega pb-4 pb-md-7 mb-4 mb-md-7">
          {block.title}
        </h3>

        <div className="markdown-preview pl-xl-16">
          <ReactMarkdown source={block.entry} />
        </div>

        {block.hasPage && (
          <div className="pl-xl-16">
            <AniLink
              cover
              direction="up"
              bg="#092431"
              className="btn btn-outline-primary rounded-lg mt-6 mt-md-7"
              to={`/${lang}/processes/${block.slug
                .toLowerCase()
                .replace(/ /g, '-')}`}
            >
              {translations[lang].find}{' '}
              <span className="d-none d-md-inline">{block.title}</span>
            </AniLink>
          </div>
        )}
      </div>

      <div className="section__block-image col-lg-6 mb-7 mb-md-12 mb-lg-0">
        <div className="section__block-image-decoration d-none d-md-block">
          <button onClick={scrollToNext}>
            <i className="fal fa-long-arrow-down"></i>
          </button>
        </div>

        <Image image={block.image} alt={block.title} />
      </div>
    </div>
  );
};

export default Block;
