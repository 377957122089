import React from 'react';

//Components
import Intro from '../../components/intro';
import SectionStats from '../../components/section-stats';
import Blocks from '../../components/blocks';
import SectionValues from '../../components/section-values';
import SectionFeatures from '../../components/section-features';
import Contacts from '../../components/contacts';

const AboutPageTemplate = ({ data, cmsPreview, lang, contactsTitle }) => {
  return (
    <>
      <Intro data={data.intro} />

      <SectionStats data={data.information} />

      <Blocks data={data.introduction} alt />

      <SectionValues lang={lang} />

      <SectionFeatures lang={lang} />

      <Contacts title={contactsTitle} cmsPreview={cmsPreview} lang={lang} />
    </>
  );
};

export default AboutPageTemplate;
