import React, { useEffect } from 'react';
import { gsap } from 'gsap';

//Components
import useObserver from './useObserver';

//Icons
import iconDiagram from '../images/icon-diagram.png';
import iconTrophy from '../images/icon-trophy.png';
import iconPresentation from '../images/icon-presentation.png';

const SectionValues = ({ lang }) => {
  const [ref, setRef, animate] = useObserver({
    init: true,
    root: null,
    rootMargin: `-20% 0% -20% 0%`,
  });

  const renderTexts = language => {
    switch (language) {
      case 'en':
        return [
          {
            title: 'Innovation and Experience',
            description:
              'We strive towards having effective communication, fast and accurate processes, and on-time delivery. By investing in the latest technolody, we are always looking to improve and innovate our facilities and machines.',
          },
          {
            title: 'Adaptive business model',
            description:
              'We are commited to making everything run as smoothly as possible on both sides when it comes to starting a new project. Our experience has shown us that every business is unique in its processes, so we have learned to adapt easily.',
          },
          {
            title: 'Valuing time',
            description:
              'Our mission is to always have an accurate estimate of how much time both us and our clients have to dedicate on a certain project. 16 years of being on the market have given us invaluable insight into putting value in our time effectively.',
          },
        ];
      case 'de':
        return [
          {
            title: 'Innovation und Erfahrung',
            description:
              'Wir streben nach effektiver Kommunikation, schnellen und präzisen Prozessen und pünktlicher Lieferung. Indem wir in die neueste Technologie investieren, sind wir stets bestrebt, unsere Anlagen und Maschinen zu verbessern und zu erneuern.',
          },
          {
            title: 'Anpassungsfähiges Geschäftsmodell',
            description:
              'Wir setzen uns dafür ein, dass beim Start eines neuen Projekts auf beiden Seiten alles so reibungslos wie möglich abläuft. Unsere Erfahrung hat uns gezeigt, dass jedes Unternehmen in seinen Prozessen einzigartig ist, so dass wir gelernt haben, uns leicht anzupassen.',
          },
          {
            title: 'Bewerten von Zeit',
            description:
              'Unsere Aufgabe ist es, immer eine genaue Schätzung darüber zu haben, wie viel Zeit sowohl wir als auch unsere Kunden für ein bestimmtes Projekt aufwenden müssen. 16 Jahre auf dem Markt haben uns einen unschätzbaren Einblick gegeben, wie wir unsere Zeit effektiv nutzen können.',
          },
        ];
      case 'bg':
        return [
          {
            title: 'Иновации и дългогодишен опит',
            description:
              'Стремим към ефективна комуникация, бързи и точни процеси и навременна доставка. Инвестирайки в най-новите технологии, ние винаги търсим как да подобрим нашите услуги.',
          },
          {
            title: 'Адаптивен бизнес модел',
            description:
              'Адаптираме се към вашите процеси и производствена верига. Стараем се усвояването на нови изделия в нашето производство да минава бързо и гладко. ',
          },
          {
            title: 'Ценим времето ви',
            description:
              'Знаем колко важно за вас е спазването на крайния срок. За това и нашата мисия е да отговорим на изискваният ви и заедно да намерим най-ефективните решения.',
          },
        ];
      default:
        return [
          {
            title: '',
            description: '',
          },
          {
            title: '',
            description: '',
          },
          {
            title: '',
            description: '',
          },
        ];
    }
  };

  useEffect(() => {
    if (ref && window.innerWidth > 767) {
      const items = ref.querySelectorAll('.section__item');
      items.forEach(item => {
        gsap.set(item, {
          opacity: 0,
          yPercent: 25,
        });
      });
    }
  }, [ref]);

  useEffect(() => {
    if (animate && window.innerWidth > 767) {
      const items = ref.querySelectorAll('.section__item');
      items.forEach((item, i) => {
        gsap.to(item, {
          opacity: 1,
          yPercent: 0,
          duration: 0.8,
          delay: i * 0.2 + 0.6,
          ease: 'power0.easeOut',
        });
      });
    }
  }, [animate, ref]);

  return (
    <section
      ref={setRef}
      className="section-values pt-lg-18 pt-mg-23 pb-lg-12 pb-mg-17"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mb-7 mb-lg-0">
            <div className="section__item d-flex flex-column h-100">
              <div className="section__item-icon d-flex align-items-center justify-content-center bg-primary">
                <img
                  width="106"
                  height="102"
                  src={iconTrophy}
                  alt={renderTexts(lang)[0].title}
                />
              </div>

              <div className="section__item-content p-5 p-md-7">
                <p className="text-headline mb-4">
                  {renderTexts(lang)[0].title}
                </p>

                <p className="text-small">{renderTexts(lang)[0].description}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-7 mb-lg-0">
            <div className="section__item d-flex flex-column h-100">
              <div className="section__item-icon d-flex align-items-center justify-content-center bg-primary">
                <img
                  width="108"
                  height="106"
                  src={iconDiagram}
                  alt={renderTexts(lang)[1].title}
                />
              </div>

              <div className="section__item-content p-5 p-md-7">
                <p className="text-headline mb-4">
                  {renderTexts(lang)[1].title}
                </p>

                <p className="text-small">{renderTexts(lang)[1].description}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-7 mb-lg-0">
            <div className="section__item d-flex flex-column h-100">
              <div className="section__item-icon d-flex align-items-center justify-content-center bg-primary">
                <img
                  width="122"
                  height="85"
                  src={iconPresentation}
                  alt={renderTexts(lang)[2].title}
                />
              </div>

              <div className="section__item-content p-5 p-md-7">
                <p className="text-headline mb-4">
                  {renderTexts(lang)[2].title}
                </p>

                <p className="text-small">{renderTexts(lang)[2].description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionValues;
