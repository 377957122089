import React from 'react';

//Components
import Block from './block';

const Blocks = ({ data, lang, alt, cmsPreview }) => {
  if (cmsPreview) {
    return (
      <section className="py-8">
        <div className="container">
          <p className="text-title text-center">
            Processes listing will be generated based on the "Processes"
            collection
          </p>
        </div>
      </section>
    );
  }

  if (!data || !data.length) {
    return null;
  }

  return (
    <section
      className={`section-blocks ${
        alt ? 'section-blocks--alt' : ''
      } pb-12 pb-md-17`}
    >
      <div className="container">
        {data
          .sort((a, b) => {
            if (a.parent === undefined || b.parent === undefined) {
              return 0;
            }

            if (a.parent.name < b.parent.name) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((block, i) => (
            <Block
              lang={lang}
              block={block}
              dataLength={data.length}
              i={i}
              key={i}
            />
          ))}
      </div>
    </section>
  );
};

export default Blocks;
